* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  /* font: 500 1rem "Quicksand", sans-serif; */
}

.home,
.pick,
.pointstable,
.profile {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
}

.mainpage {
  margin: 0;
  height: 100vh;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0 0 0 / 65%), rgba(0 0 0 / 65%)),
    url(../public/background.jpg);
  background-size: cover;
  background-position: center center;
}

.container {
  width: 350px;
  max-width: 400px;
  margin: 1rem;
  padding: 2rem;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
}

.container,
.form__input,
.form__button {
  font: 500 1rem "Quicksand", sans-serif;
}

.form--hidden {
  display: none;
}

.form > *:first-child {
  margin-top: 0;
}

.form > *:last-child {
  margin-bottom: 0;
}

.form__title {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.form__message {
  text-align: center;
  margin-bottom: 1rem;
}

.form__message--success {
  color: #4bb544;
}

.form__message--error {
  text-align: center;
  margin-bottom: 1rem;
  color: #cc3333;
}

.form__input-group {
  margin-bottom: 1rem;
}

.form__input {
  display: block;
  width: 100%;
  padding: 0.75rem;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #dddddd;
  outline: none;
  background: #eeeeee;
  transition: background 0.2s, border-color 0.2s;
}

.form__input:focus {
  border-color: #414fca;
  background: #ffffff;
}

.form__input--error {
  color: #cc3333;
  border-color: #cc3333;
}

.form__input-error-message {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #cc3333;
}

.form__button {
  width: 100%;
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1.1rem;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  background: #414fca;
}

.form__button:hover {
  background: #2b337a;
}

.form__button:active {
  transform: scale(0.98);
}

.form__text {
  text-align: center;
}

.form__link {
  padding-top: 5%;
  color: #360c7a;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.form__link:hover {
  text-decoration: underline;
}
