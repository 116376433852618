.profile-page {
    margin: 0;
    height: 100;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
  }

.profile-container {
    width: 350px;
    max-width: 400px;
    margin: 1rem;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: rgba(240, 240, 240, 0.76);
}

.profile-container h1 {
    text-align: center;
}

img {
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius:50%;
}

.profile-image {
    width:150px;
    height:150px;
    margin-bottom: 20px;
    margin-left: 25%;
}

.profile-button {
    margin-top: 10px;
    width: 100%;
    padding: 1rem 2rem;
    font-weight: bold;
    font-size: 1.1rem;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    background: #414fca;
  }
  
  .profile-button:hover {
    background: #2b337a;
  }
  
  .profile-button:active {
    transform: scale(0.98);
  }